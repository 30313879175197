* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

html, body {
  font-size: 16px;
}

body {
  color: #171333;
  font-family: arial;
  text-decoration: none;
  margin: auto;
  font-size: 1rem;
  line-height: 1.4rem;
  word-wrap: break-word;
  background-color: #FCFBFF;
}

a {
  text-decoration: none;
  color: #171333;
  -webkit-transition: color 500ms;
  transition: color 500ms;
}

a:hover {
  color: #231C4C;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.2rem 0 1.2rem 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  line-height: 1.8rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: #755fff;
}

p {
  display: block;
  -webkit-margin-before: 0rem;
  -webkit-margin-after: 0rem;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  list-style-type: square;
  margin: 0;
  padding: 0 0 0 1rem;
}

input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  outline: none;
  font-family: arial;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.4rem;
  word-wrap: break-word;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button,
input[type=date]::-webkit-clear-button {
  display: none;
}

input,
textarea {
  all: unset;
  border: none;
}

button,
input,
optgroup,
select,
textarea,
html input[type="button"],
input[type="text"],
input[type="reset"],
input[type="submit"],
button[disabled],
html input[disabled],
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="checkbox"],
input[type="radio"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea {
  -webkit-box-shadow: 0 0 0 30px #FCFBFF inset !important;
}

.textbox {
  display: block;
  margin-bottom: 1.6rem;
  width: 100%;
}

.textbox--light {
  color: #FCFBFF;
}

.textbox__paragraph {
  text-align: justify;
  margin-top: 1.6rem;
}

.textbox__paragraph:first-of-type {
  margin-top: 0;
}

.text-bold {
  font-weight: bold;
}

.text-bold-extra {
  font-weight: 1000;
}

.text-color-primary {
  color: #755fff;
}

.text-color-secondary {
  color: #ff5f99;
}

.text-color-light {
  color: #FCFBFF;
}

.text-title {
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

.text-title:first-of-type {
  margin-top: 0;
}

.text-normal {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: normal;
}

.text-justify {
  text-align: justify;
}

.text-link-inline {
  font-weight: bold;
}

.hidden {
  display: none !important;
}

.button {
  min-width: 8rem;
  width: 100%;
  display: block;
  padding: 0.4rem 1.6rem 0.4rem 1.6rem;
  margin: 1.6rem 0 0 0;
  border-radius: 0.4rem;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .button {
    min-width: 8rem;
    width: auto;
    display: inline-block;
    margin: 0 1.2rem 0 1.2rem;
  }
}

.button:hover {
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.button:first-child {
  margin-left: 0;
}

.button:last-child {
  margin-right: 0;
}

.button-no-margin {
  margin: 0;
}

.button-inactive {
  background-color: #E2E1E5 !important;
  color: #171333 !important;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) !important;
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) !important;
}

.button-inactive:hover {
  background-color: #E2E1E5 !important;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) !important;
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) !important;
}

.button-primary {
  background-color: #755fff;
  border: 0.2rem solid #755fff;
  color: #FCFBFF;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button-primary:hover {
  background-color: #5D4CCC;
  border-color: #5D4CCC;
  color: #FCFBFF;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.button-primary--light {
  background-color: #FCFBFF;
  border: 0.2rem solid #FCFBFF;
  color: #755fff;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button-primary--light:hover {
  background-color: #FCFBFF;
  color: #755fff;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.button-secondary {
  background-color: #FCFBFF;
  border: 0.2rem solid #755fff;
  color: #755fff;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button-secondary:hover {
  border-color: #5D4CCC;
  color: #5D4CCC;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.button-secondary--light {
  background-color: #755fff;
  border: 0.2rem solid #FCFBFF;
  color: #FCFBFF;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button-secondary--light:hover {
  background-color: #755fff;
  color: #FCFBFF;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.header {
  width: 100%;
  /** box-shadow: $shadow_med **/
  background-color: #755fff;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 13;
}

.header-wrapper {
  min-width: 200px;
  width: 100vw;
  max-width: 100vw;
  padding: 0.8rem 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (min-width: 768px) {
  .header-wrapper {
    -ms-flex-wrap: no-wrap;
        flex-wrap: no-wrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
}

@media (min-width: 1200px) {
  .header-wrapper {
    padding: 0;
    min-width: 90vw;
    width: 90vw;
    max-width: 90vw;
  }
}

.header-dropdown {
  width: 2rem;
  height: 2rem;
}

@media (min-width: 768px) {
  .header-dropdown {
    display: none;
  }
}

.header-dropdown--active {
  background-image: url("media/icons/close.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.header-dropdown--no-active {
  background-image: url("media/icons/menu.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.header__navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.header__navbar--left {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.header__navbar--right {
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: none;
}

@media (min-width: 768px) {
  .header__navbar--right {
    width: auto;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding-left: 1.6rem;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.header__navbar--right--active {
  display: block;
}

.header__navbar__logo {
  width: 6rem;
  height: 2rem;
  margin: 0.4rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url("media/images/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) {
  .header__navbar__logo {
    margin: 0.8rem 0;
  }
}

.header__navbar__element {
  width: 100%;
  margin: 0;
  padding: 0.8rem 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FCFBFF;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.header__navbar__element:last-of-type {
  margin-right: 0;
}

.header__navbar__element:hover {
  color: #C7BFFF;
}

@media (min-width: 768px) {
  .header__navbar__element {
    width: auto;
    margin: 0 1.2rem;
    padding: 0;
  }
}

.footer {
  width: 100%;
  background-color: #ecebef;
  min-height: 5rem;
  z-index: 11;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.footer-wrapper {
  min-width: 200px;
  width: 100vw;
  max-width: 100vw;
  padding: 3.2rem 1.6rem 0.8rem 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .footer-wrapper {
    padding: 3.2rem 3.2rem 0.8rem 3.2rem;
    min-width: 600px;
    width: 80vw;
    max-width: 80vw;
  }
}

.footer__column {
  width: 100%;
  padding: 0 0 3.2rem 0;
}

@media (min-width: 768px) {
  .footer__column {
    width: calc(50% - 3.2rem);
  }
}

@media (min-width: 1200px) {
  .footer__column {
    width: calc(25% - 3.2rem);
    padding: 1.6rem 0 1.6rem 0;
  }
}

.footer__column__element {
  width: 100%;
  margin-bottom: 0.8rem;
}

.footer__column__element:last-of-type {
  margin-bottom: 0;
}

.footer__note {
  width: 100%;
  padding: 0;
  text-align: center;
  font-size: 0.8rem;
  color: #656567;
  margin-bottom: 0.8rem;
}

.footer__note:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .footer__note {
    padding: 1.6rem 0 0 0;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background-color: #171333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.loading__animation {
  width: 60vw;
  height: 60vh;
  background-image: url("media/images/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 768px) {
  .loading__animation {
    width: 30vw;
    height: 30vh;
  }
}

@media (min-width: 1200px) {
  .loading__animation {
    width: 20vw;
    height: 20vh;
  }
}

.loading__textbox {
  width: 100%;
  margin-top: 1.6rem;
  color: #FCFBFF;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: center;
}

.cookiebanner {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: #755fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-shadow: 2px 2px 10px -2px rgba(17, 17, 17, 0.6);
          box-shadow: 2px 2px 10px -2px rgba(17, 17, 17, 0.6);
}

.cookiebanner__wrapper {
  min-width: 200px;
  width: 100vw;
  max-width: 100vw;
  padding: 3.2rem 1.6rem 1.6rem 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .cookiebanner__wrapper {
    padding: 3.2rem;
    min-width: 600px;
    width: 80vw;
    max-width: 80vw;
  }
}

.cookiebanner__container {
  width: 100%;
}

.cookiebanner__title {
  width: 100%;
}

.cookiebanner__content {
  width: 100%;
}

.cookiebanner__buttoncontainer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.error {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 21;
  background-color: #ff5f99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-shadow: 2px 2px 10px -2px rgba(17, 17, 17, 0.6);
          box-shadow: 2px 2px 10px -2px rgba(17, 17, 17, 0.6);
}

.error__wrapper {
  min-width: 200px;
  width: 100vw;
  max-width: 100vw;
  padding: 1.6rem 1.2rem 1.6rem 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: no-wrap;
      flex-wrap: no-wrap;
}

@media (min-width: 768px) {
  .error__wrapper {
    padding: 1.2rem 0;
    min-width: 600px;
    width: 80vw;
    max-width: 80vw;
  }
}

.error__content {
  min-width: 10rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.error__content__title {
  width: 100%;
  font-weight: bold;
  color: #FCFBFF;
  margin-bottom: 0.4rem;
}

.error__content__message {
  width: 100%;
  color: #FCFBFF;
}

.error__close {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1.6rem;
  cursor: pointer;
  background-image: url("media/icons/close.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) {
  .error__close {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.content-container {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.content-container--primary {
  background-color: #755fff;
  color: #FCFBFF;
}

.content-wrapper {
  min-width: 200px;
  width: 100vw;
  max-width: 100vw;
  padding: 0 1.6rem;
}

@media (min-width: 1200px) {
  .content-wrapper {
    min-width: 600px;
    width: 80vw;
    max-width: 80vw;
  }
}

.section {
  width: 100%;
  margin-top: 3.2rem;
}

.section:last-of-type {
  margin-bottom: 3.2rem;
}

@media (min-width: 768px) {
  .section {
    margin-top: 6.4rem;
  }
  .section:last-of-type {
    margin-bottom: 6.4rem;
  }
}

.input {
  border: 1px solid #171333;
  border-radius: 0.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 1.6rem;
}

.input:last-of-type {
  margin-bottom: 1.6rem;
}

.input--disabled {
  border-color: #CBCACE;
  color: #CBCACE;
}

.hero-container {
  width: 100%;
  background-color: #755fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  z-index: 8;
}

.hero-wrapper {
  width: 90vw;
  min-height: 90vh;
  padding-bottom: 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: auto auto auto auto 0;
      grid-template-rows: auto auto auto auto 0;
  -webkit-column-gap: 1.6rem;
          column-gap: 1.6rem;
  row-gap: 1.2rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .hero-wrapper {
    -ms-grid-columns: 1fr 2fr;
        grid-template-columns: 1fr 2fr;
    -ms-grid-rows: 1fr auto auto auto 1fr;
        grid-template-rows: 1fr auto auto auto 1fr;
    row-gap: 3.2rem;
    -webkit-column-gap: 3.2rem;
            column-gap: 3.2rem;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }
}

@media (min-width: 1200px) {
  .hero-wrapper {
    -webkit-column-gap: 6.4rem;
            column-gap: 6.4rem;
  }
}

.hero__title {
  width: 100%;
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
}

@media (min-width: 768px) {
  .hero__title {
    margin-top: 0;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2;
  }
}

.hero__textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}

@media (min-width: 768px) {
  .hero__textbox {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 3;
    grid-row: 3;
  }
}

.hero__buttonbox {
  width: 100%;
  margin: 0;
  padding: 0;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 3;
  grid-row: 3;
}

@media (min-width: 768px) {
  .hero__buttonbox {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 4;
    grid-row: 4;
  }
}

.hero__note {
  width: 100%;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 4;
  grid-row: 4;
  font-size: 0.8rem;
  line-height: 1rem;
}

@media (min-width: 768px) {
  .hero__note {
    text-align: center;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / span 2;
    -ms-grid-row: 6;
    grid-row: 6;
    margin-bottom: 0.8rem;
  }
}

.hero__illustration {
  width: 100%;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 5;
  grid-row: 5;
}

@media (min-width: 768px) {
  .hero__illustration {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 5;
    grid-row: 1 / span 5;
  }
}

.hero__illustration__phone {
  position: absolute;
  height: 93%;
  width: 100%;
  top: 7%;
  left: 0%;
  background-image: url("media/images/phone.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 3;
  -webkit-animation: hero-phone-move;
          animation: hero-phone-move;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-direction: forward;
          animation-direction: forward;
}

@-webkit-keyframes hero-phone-move {
  0% {
    left: 0%;
    top: 5%;
  }
  25% {
    left: 6%;
    top: 9%;
  }
  50% {
    left: 1%;
    top: 12%;
  }
  75% {
    left: -4%;
    top: 8%;
  }
  100% {
    left: 0%;
    top: 5%;
  }
}

@keyframes hero-phone-move {
  0% {
    left: 0%;
    top: 5%;
  }
  25% {
    left: 6%;
    top: 9%;
  }
  50% {
    left: 1%;
    top: 12%;
  }
  75% {
    left: -4%;
    top: 8%;
  }
  100% {
    left: 0%;
    top: 5%;
  }
}

.hero__illustration__background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("media/images/hero_background_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.hero__illustration__background-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("media/images/hero_background_ov.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
}

.section-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.form-container {
  width: 80vw;
  max-width: 600px;
  background-color: #ecebef;
  padding: 3.2rem 1.6rem 3.2rem 1.6rem;
  border-radius: 0.4rem;
  margin-bottom: 3.2rem;
}

.form-container:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .form-container {
    padding: 3.2rem;
  }
}

.form__element {
  width: 100%;
  margin-bottom: 1.6rem;
}

.form__title {
  font-weight: bold;
}

.form__input-container {
  width: 100%;
  padding: 1.2rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.form__input-container--half {
  width: calc(50% - 1.6rem);
}

.form__input__headline {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 0.4rem;
}

.form__input__headline__label {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.form__input__headline__infobox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form__input__headline__infobox__icon {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  margin-left: 0.8rem;
  cursor: pointer;
}

.form__input__headline__infobox__icon--required {
  background-image: url("./media/icons/asterisk.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.form__input__headline__infobox__icon--information {
  background-image: url("./media/icons/information.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.form__input__text,
.form__input__number,
.form__input__price {
  width: 100%;
  background-color: #FCFBFF;
  border: 1px solid #CBCACE;
  cursor: pointer;
  border-radius: 0.4rem;
}

.form__input__text--active,
.form__input__number--active,
.form__input__price--active,
.form__input__dropdown--active {
  border-color: #755fff;
}

.form__input__text__input,
.form__input__number__input,
.form__input__price__input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0.8rem;
  border-radius: 0.4rem;
}

.form__buttons-container {
  width: 100%;
  padding: 1.2rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form__buttons__button:first-of-type {
  margin-right: 0;
}

.account__buttonbox {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  row-gap: 3.2rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .account__buttonbox {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    -webkit-column-gap: 3.2rem;
            column-gap: 3.2rem;
  }
}

@media (min-width: 1200px) {
  .account__buttonbox {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    -webkit-column-gap: 3.2rem;
            column-gap: 3.2rem;
  }
}

.account__buttonbox__button {
  width: 100%;
  padding: 1.2rem 1.6rem;
  background-color: #E2E1E5;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.account__buttonbox__button:hover {
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
  color: #755fff;
}

.account__buttonbox__button__icon {
  width: 2rem;
  height: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 1.2rem;
}

.account__buttonbox__button__icon--inner {
  width: 80%;
  height: 80%;
  fill: #755fff;
}

.account__buttonbox__button__label {
  font-weight: bold;
  color: #755fff;
}

.scanlist-container {
  width: 100%;
}

.scanlist__scan {
  width: 100%;
  display: block;
  margin-top: 1.6rem;
  padding: 1.2rem 1.6rem;
  background-color: #ecebef;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.scanlist__scan:hover {
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.scanlist__scan-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.scanlist__scan-wrapper:first-of-type {
  margin-top: 0;
}

.scanlist__scan__mole {
  min-width: 8rem;
  width: 50%;
}

@media (min-width: 768px) {
  .scanlist__scan__mole {
    width: 25%;
  }
}

.scanlist__scan__date {
  min-width: 10rem;
  width: 50%;
}

@media (min-width: 768px) {
  .scanlist__scan__date {
    width: 25%;
  }
}

.scanlist__scan__result {
  min-width: 8rem;
  width: 50%;
  font-weight: bold;
}

@media (min-width: 768px) {
  .scanlist__scan__result {
    width: 25%;
  }
}

.scanlist__scan__comment {
  width: 100%;
}

@media (min-width: 768px) {
  .scanlist__scan__comment {
    width: 25%;
  }
}

.scanlist__scan__result--risk {
  color: #ff5f99;
}

.scan-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.scan__summary {
  width: 100%;
  margin-top: 1.6rem;
}

.scan__details {
  width: 100%;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  row-gap: 0;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .scan__details {
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
    justify-items: start;
    -webkit-column-gap: 3.2rem;
            column-gap: 3.2rem;
    row-gap: 1.6rem;
  }
}

.scan__details__label {
  max-width: 15rem;
  padding-top: 1.2rem;
}

.scan__details__label:first-of-type {
  padding-top: 0;
}

@media (min-width: 768px) {
  .scan__details__label {
    padding-top: 0;
  }
}

.scan__details__label__description {
  font-size: 0.8rem;
  color: #656567;
}

@media (min-width: 768px) {
  .scan__details__label__description {
    margin-top: 0.8rem;
  }
}

.scan__details__value {
  padding-bottom: 1.2rem;
  border-bottom: 1px solid #E2E1E5;
  font-weight: bold;
}

.scan__details__value:last-of-type {
  padding-bottom: 0;
  border-bottom: none;
}

@media (min-width: 768px) {
  .scan__details__value {
    padding-bottom: 0;
    border-bottom: none;
    font-weight: normal;
  }
}

.scan__image {
  min-width: 200px;
  width: 100%;
  min-height: 200px;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
}

.scan__legal-notes {
  width: 100%;
}

.scan__buttonbox {
  width: 100%;
  margin-top: 3.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.section-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 1.6rem;
}

@media (min-width: 768px) {
  .section-check {
    margin-top: 3.2rem;
  }
}

.check-container {
  width: 80vw;
  max-width: 800px;
  background-color: #ecebef;
  padding: 1.6rem;
  border-radius: 0.4rem;
  margin-bottom: 3.2rem;
}

.check-container:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .check-container {
    padding: 3.2rem;
  }
}

.check__image-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.check__image-wrapper {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: auto;
  position: relative;
}

.check__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
}

@media (min-width: 768px) {
  .check__image {
    width: 400px;
    height: 400px;
  }
}

.check__image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("media/images/overlay.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) {
  .check__image-overlay {
    width: 400px;
    height: 400px;
  }
}

.check__image__icon {
  width: 6rem;
  height: 6rem;
  background-color: #755fff;
  font-size: 4rem;
  line-height: 6rem;
  background-color: #FCFBFF;
  color: #CBCACE;
  text-align: center;
  border: 1px solid #CBCACE;
  border-radius: 0.4rem;
  cursor: pointer;
  -webkit-box-shadow: 2px 2px 10px -2px rgba(17, 17, 17, 0.6);
          box-shadow: 2px 2px 10px -2px rgba(17, 17, 17, 0.6);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.check__image__icon:hover {
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.check__buttonbox {
  width: 100%;
  margin-top: 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (min-width: 768px) {
  .check__buttonbox {
    margin-top: 3.2rem;
  }
}

/* .check__checkbox
    width: 100%
    margin-top: $spacing_xl */
/* .check__checkbox__element
    width: 100%
    padding-bottom: $spacing_s
    padding-top: $spacing_s
    border-bottom: $border-subtle
    &:first-of-type
        padding-top: 0
    &:last-of-type
        padding-bottom: 0
        border-bottom: none */
.check__textbox {
  width: 100%;
  margin-top: 1.6rem;
}

@media (min-width: 768px) {
  .check__textbox {
    margin-top: 3.2rem;
  }
}

.check__textbox--center {
  text-align: center;
}

.check__textbox__paragraph {
  text-align: justify;
  margin-top: 1.6rem;
}

.check__textbox__paragraph:first-of-type {
  margin-top: 0;
}

.check__notes {
  width: 100%;
  margin-top: 3.2rem;
  font-size: 0.8rem;
  line-height: 1rem;
}

.check__score {
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.check__score--high {
  color: #ff5f99;
}

.check__score--medium {
  color: orange;
}

.check__score--low {
  color: green;
}

.check__saliency-map-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.check__saliency-map__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
}

@media (min-width: 768px) {
  .check__saliency-map__image {
    width: 400px;
    height: 400px;
  }
}

.check__process {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.check__process__element {
  padding: 0.4rem 0.4rem;
  text-align: center;
  color: #656567;
  display: none;
}

@media (min-width: 768px) {
  .check__process__element {
    display: inline-block;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
  .check__process__element:first-of-type {
    margin-left: 0;
  }
  .check__process__element:last-of-type {
    margin-right: 0;
  }
}

.check__process__element--active {
  color: #755fff;
  font-weight: bold;
  display: inline-block;
}

.check__process__element--done {
  color: #171333;
}

/* .check__process__element-logo
    width: 4rem
    height: 2rem
    padding: 0
    background-image: url('media/images/logo-grey.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    display: none
    @include breakpoint(tablet)
        display: inline-block */
/* .check__process__element-logo--active
    background-image: url('media/images/logo-primary.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    display: inline-block */
/* .check__process__element-logo--done
    background-image: url('media/images/logo-primary.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center */
.check__process__element__arrow {
  width: 1rem;
  height: 1rem;
  background-image: url("./media/icons/next.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
}

@media (min-width: 768px) {
  .check__process__element__arrow {
    display: block;
  }
}

.section-instructions {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  row-gap: 6.4rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .section-instructions {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 6.4rem;
            column-gap: 6.4rem;
  }
}

.check__instruction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.check__instruction__title {
  width: 100%;
  margin-bottom: 1.2rem;
  text-align: center;
}

.check__instruction__image {
  width: calc(50% - 1.2rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.check__instruction__image__img {
  width: 100%;
  min-height: 12rem;
}

.check__instruction__image__img--positive {
  background-image: url("media/images/instructions-positive.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.check__instruction__image__img--negative-center {
  background-image: url("media/images/instructions-1-negative.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.check__instruction__image__img--negative-size {
  background-image: url("media/images/instructions-2-negative.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.check__instruction__image__img--negative-light {
  background-image: url("media/images/instructions-3-negative.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.check__instruction__image__img--negative-visible {
  background-image: url("media/images/instructions-4-negative.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.check__instruction__image__img--negative-background {
  background-image: url("media/images/instructions-5-negative.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.check__instruction__image__icon {
  margin-top: 0.8rem;
  height: 2rem;
  width: 2rem;
}

.check__instruction__image__icon--yes {
  fill: #755fff;
}

.check__instruction__image__icon--no {
  fill: #ff5f99;
}

.process-wrapper {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 1fr auto 1fr auto 1fr;
      grid-template-rows: 1fr auto 1fr auto 1fr;
  -webkit-column-gap: 1.2rem;
          column-gap: 1.2rem;
  row-gap: 1.2rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .process-wrapper {
    -ms-grid-columns: 1fr auto 1fr auto 1fr;
        grid-template-columns: 1fr auto 1fr auto 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    row-gap: 3.2rem;
    -webkit-column-gap: 1.6rem;
            column-gap: 1.6rem;
  }
}

@media (min-width: 1200px) {
  .process-wrapper {
    -webkit-column-gap: 3.2rem;
            column-gap: 3.2rem;
  }
}

.process__element {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.process__element__icon {
  width: 12rem;
  height: 12rem;
  margin-bottom: 1.6rem;
}

.process__element__icon--1 {
  background-image: url("media/icons/icon_working_1.png");
  background-size: 80% 80%;
  background-repeat: no-repeat;
  background-position: center;
}

.process__element__icon--2 {
  background-image: url("media/icons/icon_working_2.png");
  background-size: 80% 80%;
  background-repeat: no-repeat;
  background-position: center;
}

.process__element__icon--3 {
  background-image: url("media/icons/icon_working_3.png");
  background-size: 80% 80%;
  background-repeat: no-repeat;
  background-position: center;
}

.process__element__textbox {
  width: 100%;
  text-align: justify;
}

.process__arrow {
  width: 2rem;
  height: 100%;
  background-image: url("./media/icons/next.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.functioning-wrapper {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  row-gap: 6.4rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .functioning-wrapper {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    row-gap: 6.4rem;
    -webkit-column-gap: 6.4rem;
            column-gap: 6.4rem;
  }
}

.functioning__element {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.functioning__element__icon {
  width: 40vw;
  height: 40vw;
  border-radius: 50%;
  background-color: #755fff;
  border: 1px solid #755fff;
  margin-bottom: 1.6rem;
}

@media (min-width: 768px) {
  .functioning__element__icon {
    width: 12rem;
    height: 12rem;
  }
}

.functioning__element__icon--1 {
  background-image: url("media/icons/icon_functioning_1.png");
  background-size: 70% 70%;
  background-repeat: no-repeat;
  background-position: center;
}

.functioning__element__icon--2 {
  background-image: url("media/icons/icon_functioning_2.png");
  background-size: 70% 70%;
  background-repeat: no-repeat;
  background-position: center;
}

.functioning__element__icon--3 {
  background-image: url("media/icons/icon_functioning_3.png");
  background-size: 70% 70%;
  background-repeat: no-repeat;
  background-position: center;
}

.functioning__element__icon--4 {
  background-image: url("media/icons/icon_functioning_4.png");
  background-size: 70% 70%;
  background-repeat: no-repeat;
  background-position: center;
}

.functioning__element__textbox {
  width: 100%;
  max-width: 24rem;
  text-align: justify;
}

.section-logos {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.logos__element {
  width: 8rem;
  height: 6rem;
  margin: 0 1.6rem 0 1.6rem;
}

.logos__element--mit {
  background-image: url("media/images/reference_mit.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logos__element--harvard {
  background-image: url("media/images/reference_harvard.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logos__element--sandbox {
  background-image: url("media/images/reference_sandbox.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logos__element--ilab {
  background-image: url("media/images/reference_ilab.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
