// Config

// Game specific colors
$color_primary: #755fff
$color_secondary: #ff5f99
$color_tertiary: #ff755f
$color_primary_shade_1: #5D4CCC
$color_primary_shade_2: #463999
$color_primary_shade_3: #2E2666
$color_primary_tint_1: #907EFF
$color_primary_tint_2: #AC9FFF
$color_primary_tint_3: #C7BFFF
$color_secondary_shade_1: #CC4C7A
$color_secondary_tint_1: #FF7EAD
$color_tertiary_shade_1: #CC5D4C
$color_tertiary_tint_1: #FF907E
$color_light: #FCFBFF
$color_grey_extralight: #ecebef
$color_grey_light: #E2E1E5
$color_grey: #CBCACE
$color_grey_dark: #656567
$color_dark: #171333
$color_dark_secondary: #231C4C
$color_dark_r: 20
$color_dark_g: 21
$color_dark_b: 21

// Universal config
$bp-tablet: 768px
$bp-desktop: 1200px

$windowsize: 100vw
$windowsize_desktop: 80vw
$windowsize_min: 200px
$windowsize_min_desktop: 600px
$windowsize_max: 100vw
$windowsize_max_desktop: 80vw
$windowsize_slim: 90vw
$windowsize_slim_desktop: 800px

$windowsize_wide_desktop: 90vw

$spacing_xxs: 0.2rem
$spacing_xs: 0.4rem
$spacing_s: 0.8rem
$spacing_m: 1.2rem
$spacing_l: 1.6rem
$spacing_xl: 3.2rem
$spacing_xxl: 6.4rem

$border-radius: 0.4rem
$border-radius_s: 0.2rem

$shadow_s: 2px 2px 4px -2px rgba(17,17,17,0.6)
$shadow_med: 0 0 20px -10px rgba(17,17,17,0.3)
$shadow_med_none: 0 0 20px -10px rgba(17,17,17,0)
$shadow_strong: 2px 2px 10px -2px rgba(17,17,17,0.6)

$fontsize_s: 0.8rem
$fontsize_reg: 1.0rem
$fontsize_l: 1.2rem
$fontsize_xl: 1.4rem
$fontsize_xxl: 1.8rem

$lineheight_s: 1.0rem
$lineheight_reg: 1.4rem
$lineheight_l: 1.6rem
$lineheight_xl: 1.8rem
$lineheight_xxl: 2.4rem

$border-subtle: 1px solid $color_grey_light
$border-reg: 1px solid $color_grey
$border-bold: 2px solid $color_primary

$transitiontime: 500ms


// Mixins

@mixin breakpoint($point)
  @if $point == largedesktop
    @media (min-width: 1600px)
      @content
  @if $point == desktop
    @media (min-width: 1200px)
      @content
  @else if $point == laptop
    @media (min-width: 1024px)
      @content
  @else if $point == tablet
    @media (min-width: 768px)
      @content
  @else if $point == phablet
    @media (min-width: 600px)
      @content
  @else if $point == mobileonly
    @media (max-width: 600px)
      @content


=transform($property)
  -webkit-transform: $property
  -ms-transform: $property
  transform: $property
//+transform(rotate(30deg))