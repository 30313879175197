@import 'config'

@import '../../global/style/base'





// Header

.header
    width: 100%
    /** box-shadow: $shadow_med **/
    background-color: $color-primary
    position: sticky
    position: -webkit-sticky
    top: 0
    display: flex
    justify-content: center
    z-index: 13


.header-wrapper
    min-width: $windowsize_min
    width: $windowsize
    max-width: $windowsize_max
    padding: $spacing_s $spacing_l
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap
    @include breakpoint(tablet)
        flex-wrap: no-wrap
        align-items: stretch
    @include breakpoint(desktop)
        padding: 0
        min-width: $windowsize_wide_desktop
        width: $windowsize_wide_desktop
        max-width: $windowsize_wide_desktop

.header-dropdown
    width: 2rem
    height: 2rem
    @include breakpoint(tablet)
        display: none

.header-dropdown--active
    background-image: url('media/icons/close.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.header-dropdown--no-active
    background-image: url('media/icons/menu.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.header__navbar
    display: flex
    align-items: stretch

.header__navbar--left
    justify-content: flex-start

.header__navbar--right
    width: 100%
    justify-content: center
    padding-left: 0
    flex-wrap: wrap
    display: none
    @include breakpoint(tablet)
        width: auto
        justify-content: flex-end
        padding-left: $spacing_l
        flex-wrap: nowrap
        display: flex

.header__navbar--right--active
    display: block

.header__navbar__logo
    width: 6rem
    height: 2rem
    margin: $spacing_xs 0
    display: flex
    align-items: center
    background-image: url('media/images/logo.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    @include breakpoint(tablet)
        margin: $spacing_s 0

.header__navbar__element
    width: 100%
    margin: 0
    padding: $spacing_s 0
    cursor: pointer
    display: flex
    align-items: center
    color: $color_light
    transition: all $transitiontime
    &:last-of-type
        margin-right: 0
    &:hover
        color: $color_primary_tint_3
    @include breakpoint(tablet)
        width: auto
        margin: 0 $spacing_m
        padding: 0





// Footer

.footer
    width: 100%
    background-color: $color_grey_extralight
    min-height: 5rem
    z-index: 11
    display: flex
    justify-content: center
    align-items: flex-start

.footer-wrapper
    min-width: $windowsize_min
    width: $windowsize
    max-width: $windowsize_max
    padding: $spacing_xl $spacing_l $spacing_s $spacing_l
    display: flex
    justify-content: space-between
    align-items: flex-start
    flex-wrap: wrap
    @include breakpoint(desktop)
        padding: $spacing_xl $spacing_xl $spacing_s $spacing_xl
        min-width: $windowsize_min_desktop
        width: $windowsize_desktop
        max-width: $windowsize_max_desktop

.footer__column
    width: 100%
    padding: 0 0 $spacing_xl 0
    @include breakpoint(tablet)
        width: calc(50% - #{$spacing_xl})
    @include breakpoint(desktop)
        width: calc(25% - #{$spacing_xl})
        padding: $spacing_l 0 $spacing_l 0

.footer__column__element
    width: 100%
    margin-bottom: $spacing_s
    &:last-of-type
        margin-bottom: 0

.footer__note
    width: 100%
    padding: 0
    text-align: center
    font-size: $fontsize_s
    color: $color_grey_dark
    margin-bottom: $spacing_s
    &:last-of-type
        margin-bottom: 0
    @include breakpoint(tablet)
        padding: $spacing_l 0 0 0





// Loading

.loading
    width: 100vw
    height: 100vh
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 30
    background-color: $color_dark
    display: flex
    justify-content: center
    align-content: center
    flex-wrap: wrap

.loading__animation
    width: 60vw
    height: 60vh
    background-image: url('media/images/logo.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    display: flex
    justify-content: center
    align-items: center
    @include breakpoint(tablet)
        width: 30vw
        height: 30vh
    @include breakpoint(desktop)
        width: 20vw
        height: 20vh

.loading__textbox
    width: 100%
    margin-top: $spacing_l
    color: $color_light
    font-size: $fontsize_l
    line-height: $lineheight_l
    text-align: center





// Cookiebanner

.cookiebanner
    width: 100%
    position: fixed
    bottom: 0
    left: 0
    right: 0
    z-index: 20
    background-color: $color_primary
    display: flex
    justify-content: center
    align-items: flex-start
    box-shadow: $shadow_strong

.cookiebanner__wrapper
    min-width: $windowsize_min
    width: $windowsize
    max-width: $windowsize_max
    padding: $spacing_xl $spacing_l $spacing_l $spacing_l
    display: flex
    justify-content: space-between
    align-items: flex-start
    flex-wrap: wrap
    @include breakpoint(desktop)
        padding: $spacing_xl
        min-width: $windowsize_min_desktop
        width: $windowsize_desktop
        max-width: $windowsize_max_desktop

.cookiebanner__container
    width: 100%

.cookiebanner__title
    width: 100%

.cookiebanner__content
    width: 100%

.cookiebanner__buttoncontainer
    width: 100%
    display: flex
    justify-content: flex-end





// Error

.error
    width: 100%
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 21
    background-color: $color_secondary
    display: flex
    justify-content: center
    align-items: flex-start
    box-shadow: $shadow_strong

.error__wrapper
    min-width: $windowsize_min
    width: $windowsize
    max-width: $windowsize_max
    padding: $spacing_l $spacing_m $spacing_l $spacing_m
    display: flex
    justify-content: space-between
    align-items: flex-start
    flex-wrap: no-wrap
    @include breakpoint(tablet)
        padding: $spacing_m 0
        min-width: $windowsize_min_desktop
        width: $windowsize_desktop
        max-width: $windowsize_max_desktop

.error__content
    min-width: 10rem
    flex-grow: 1

.error__content__title
    width: 100%
    font-weight: bold
    color: $color_light
    margin-bottom: $spacing_xs

.error__content__message
    width: 100%
    color: $color_light

.error__close
    width: $spacing_l
    height: $spacing_l
    margin-left: $spacing_l
    cursor: pointer
    background-image: url('media/icons/close.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    @include breakpoint(tablet)
        width: $spacing_m
        height: $spacing_m








// Semi-generic classes

.content-container
    width: 100%
    position: relative
    display: flex
    flex-wrap: wrap
    justify-content: center

.content-container--primary
    background-color: $color_primary
    color: $color_light

.content-wrapper
    min-width: $windowsize_min
    width: $windowsize
    max-width: $windowsize_max
    padding: 0 $spacing_l
    @include breakpoint(desktop)
        min-width: $windowsize_min_desktop
        width: $windowsize_desktop
        max-width: $windowsize_max_desktop

.section
    width: 100%
    margin-top: $spacing_xl
    &:last-of-type
        margin-bottom: $spacing_xl
    @include breakpoint(tablet)
        margin-top: $spacing_xxl
        &:last-of-type
            margin-bottom: $spacing_xxl

.input
    border: 1px solid $color_dark
    border-radius: $border-radius
    display: flex
    justify-content: flex-start
    margin-bottom: $spacing_l
    &:last-of-type
        margin-bottom: $spacing_l

.input--disabled
    border-color: $color_grey
    color: $color_grey





// Hero

.hero-container
    width: 100%
    background-color: $color_primary
    display: flex
    justify-content: center
    align-items: flex-start
    z-index: 8

.hero-wrapper
    width: $windowsize_wide_desktop
    min-height: 90vh
    padding-bottom: 0
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: auto auto auto auto 0
    column-gap: $spacing_l
    row-gap: $spacing_m
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: center
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 2fr
        grid-template-rows: 1fr auto auto auto 1fr
        row-gap: $spacing_xl
        column-gap: $spacing_xl
        align-content: stretch
    @include breakpoint(desktop)
        column-gap: $spacing_xxl

.hero__title
    width: 100%
    margin: 0
    font-size: $fontsize_xl
    line-height: $lineheight_xl
    grid-column: 1
    grid-row: 1
    @include breakpoint(tablet)
        margin-top: 0
        grid-column: 1
        grid-row: 2

.hero__textbox
    width: 100%
    margin: 0
    padding: 0
    grid-column: 1
    grid-row: 2
    @include breakpoint(tablet)
        grid-column: 1
        grid-row: 3

.hero__buttonbox
    width: 100%
    margin: 0
    padding: 0
    grid-column: 1
    grid-row: 3
    @include breakpoint(tablet)
        grid-column: 1
        grid-row: 4
    
.hero__note
    width: 100%
    grid-column: 1
    grid-row: 4
    font-size: $fontsize_s
    line-height: $lineheight_s
    @include breakpoint(tablet)
        text-align: center
        grid-column: 1 / span 2
        grid-row: 6
        margin-bottom: $spacing_s

.hero__illustration
    width: 100%
    grid-column: 1
    grid-row: 5
    @include breakpoint(tablet)
        grid-column: 2
        grid-row: 1 / span 5

.hero__illustration__phone
    position: absolute
    height: 93%
    width: 100%
    top: 7%
    left: 0%
    background-image: url('media/images/phone.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    z-index: 3
    animation: hero-phone-move
    animation-duration: 6s
    animation-iteration-count: infinite
    animation-timing-function: ease-in-out
    animation-direction: forward

@keyframes hero-phone-move
    0%
        left: 0%
        top: 5%

    25%
        left: 6%
        top: 9%

    50%
        left: 1%
        top: 12%

    75%
        left: -4%
        top: 8%
        
    100%
        left: 0%
        top: 5%

.hero__illustration__background
    position: absolute
    width: 100%
    height: 100%
    background-image: url('media/images/hero_background_bg.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    z-index: 1

.hero__illustration__background-overlay
    position: absolute
    width: 100%
    height: 100%
    background-image: url('media/images/hero_background_ov.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    z-index: 2





// Form

.section-form
    display: flex
    justify-content: center
    flex-wrap: wrap

.form-container
    width: $windowsize_desktop
    max-width: $windowsize_min_desktop
    background-color: $color_grey_extralight
    padding: $spacing_xl $spacing_l $spacing_xl $spacing_l
    border-radius: $border-radius
    margin-bottom: $spacing_xl
    &:last-of-type
        margin-bottom: 0
    @include breakpoint(tablet)
        padding: $spacing_xl

.form__element
    width: 100%
    margin-bottom: $spacing_l

.form__title
    font-weight: bold

.form__input-container
    width: 100%
    padding: $spacing_m 0
    display: flex
    flex-direction: column
    justify-content: flex-start

.form__input-container--half
    width: calc(50% - #{$spacing_l})
        
.form__input__headline
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: $spacing_xs

.form__input__headline__label
    flex-grow: 1

.form__input__headline__infobox
    display: flex
    justify-content: flex-end
    align-items: center

.form__input__headline__infobox__icon
    width: 1rem
    height: 1rem
    border-radius: 0.5rem
    margin-left: $spacing_s
    cursor: pointer

.form__input__headline__infobox__icon--required
    background-image: url("./media/icons/asterisk.png")
    background-repeat: no-repeat
    background-position: center
    background-size: contain

.form__input__headline__infobox__icon--information
    background-image: url("./media/icons/information.png")
    background-repeat: no-repeat
    background-position: center
    background-size: contain

.form__input__text,
.form__input__number,
.form__input__price
    width: 100%
    background-color: $color_light
    border: 1px solid $color_grey
    cursor: pointer
    border-radius: $border_radius

.form__input__text--active,
.form__input__number--active,
.form__input__price--active,
.form__input__dropdown--active
    border-color: $color_primary

.form__input__text__input,
.form__input__number__input,
.form__input__price__input
    width: 100%
    box-sizing: border-box
    padding: $spacing_s
    border-radius: $border_radius

.form__buttons-container
    width: 100%
    padding: $spacing_m 0
    display: flex
    flex-direction: row-reverse
    justify-content: flex-start
    align-items: center

.form__buttons__button
    &:first-of-type
        margin-right: 0





// Account

.account__buttonbox
    width: 100%
    display: grid
    grid-template-columns: 1fr
    row-gap: $spacing_xl
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: center
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr 1fr 1fr
        column-gap: $spacing_xl
    @include breakpoint(desktop)
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr
        column-gap: $spacing_xl

.account__buttonbox__button
    width: 100%
    padding: $spacing_m $spacing_l
    background-color: $color_grey_light
    box-shadow: $shadow_med
    transition: all $transitiontime
    display: flex
    justify-content: flex-start
    align-items: center
    &:hover
        box-shadow: $shadow_med_none
        color: $color_primary

.account__buttonbox__button__icon
    width: 2rem
    height: 2rem
    display: flex
    justify-content: center
    align-items: center
    margin-right: $spacing_m

.account__buttonbox__button__icon--inner
    width: 80%
    height: 80%
    fill: $color_primary

.account__buttonbox__button__label
    font-weight: bold
    color: $color_primary





// Account

.scanlist-container
    width: 100%

.scanlist__scan
    width: 100%
    display: block
    margin-top: $spacing_l
    padding: $spacing_m $spacing_l
    background-color: $color_grey_extralight
    box-shadow: $shadow_med
    transition: all $transitiontime
    &:hover
        box-shadow: $shadow_med_none

.scanlist__scan-wrapper
    display: flex
    flex-wrap: wrap
    &:first-of-type
        margin-top: 0
    @include breakpoint(tablet)

.scanlist__scan__mole
    min-width: 8rem
    width: 50%
    @include breakpoint(tablet)
        width: 25%

.scanlist__scan__date
    min-width: 10rem
    width: 50%
    @include breakpoint(tablet)
        width: 25%

.scanlist__scan__result
    min-width: 8rem
    width: 50%
    font-weight: bold
    @include breakpoint(tablet)
        width: 25%

.scanlist__scan__comment
    width: 100%
    @include breakpoint(tablet)
        width: 25%

.scanlist__scan__result--risk
    color: $color_secondary

.scan-container
    width: 100%
    display: flex
    justify-content: flex-start
    flex-wrap: wrap

.scan__summary
    width: 100%
    margin-top: $spacing_l

.scan__details
    width: 100%
    margin-top: $spacing_xl
    margin-bottom: $spacing_xl
    display: grid
    grid-template-columns: 1fr
    row-gap: 0
    justify-items: stretch
    align-items: stretch
    justify-content: start
    align-content: center
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: auto auto
        justify-items: start
        column-gap: $spacing_xl
        row-gap: $spacing_l

.scan__details__label
    max-width: 15rem
    padding-top: $spacing_m
    &:first-of-type
        padding-top: 0
    @include breakpoint(tablet)
        padding-top: 0

.scan__details__label__label

.scan__details__label__description
    font-size: $fontsize_s
    color: $color_grey_dark
    @include breakpoint(tablet)
        margin-top: $spacing_s

.scan__details__value
    padding-bottom: $spacing_m
    border-bottom: $border-subtle
    font-weight: bold
    &:last-of-type
        padding-bottom: 0
        border-bottom: none
    @include breakpoint(tablet)
        padding-bottom: 0
        border-bottom: none
        font-weight: normal

.scan__image
    min-width: 200px
    width: 100%
    min-height: 200px
    height: 100%
    max-width: 400px
    max-height: 400px

.scan__legal-notes
    width: 100%

.scan__buttonbox
    width: 100%
    margin-top: $spacing_xl
    display: flex
    justify-content: flex-end
    flex-wrap: wrap





// Check

.section-check
    display: flex
    justify-content: center
    flex-wrap: wrap
    margin-top: $spacing_l
    @include breakpoint(tablet)
        margin-top: $spacing_xl

.check-container
    width: $windowsize_desktop
    max-width: $windowsize_slim_desktop
    background-color: $color_grey_extralight
    padding: $spacing_l
    border-radius: $border-radius
    margin-bottom: $spacing_xl
    &:last-of-type
        margin-bottom: 0
    @include breakpoint(tablet)
        padding: $spacing_xl

.check__image-container
    width: 100%
    display: flex
    justify-content: center

.check__image-wrapper
    max-width: fit-content
    display: flex
    justify-content: center
    width: auto
    position: relative

.check__image
    width: 100%
    height: 100%
    object-fit: cover
    box-shadow: $shadow_med
    @include breakpoint(tablet)
        width: 400px
        height: 400px

.check__image-overlay
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    background-image: url('media/images/overlay.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    @include breakpoint(tablet)
        width: 400px
        height: 400px

.check__image__icon
    width: 6rem
    height: 6rem
    //background-image: url("./media/icons/camera.png")
    //background-repeat: no-repeat
    //background-position: center
    //background-size: 50%
    background-color: $color_primary
    font-size: 4rem
    line-height: 6rem
    background-color: $color_light
    color: $color_grey
    text-align: center
    border: $border-reg
    border-radius: $border-radius
    cursor: pointer
    box-shadow: $shadow_strong
    transition: all $transitiontime
    &:hover
        box-shadow: $shadow_med_none

.check__buttonbox
    width: 100%
    margin-top: $spacing_l
    display: flex
    justify-content: flex-end
    flex-wrap: wrap
    @include breakpoint(tablet)
        margin-top: $spacing_xl

/* .check__checkbox
    width: 100%
    margin-top: $spacing_xl

/* .check__checkbox__element
    width: 100%
    padding-bottom: $spacing_s
    padding-top: $spacing_s
    border-bottom: $border-subtle
    &:first-of-type
        padding-top: 0
    &:last-of-type
        padding-bottom: 0
        border-bottom: none

.check__textbox
    width: 100%
    margin-top: $spacing_l
    @include breakpoint(tablet)
        margin-top: $spacing_xl

.check__textbox--center
    text-align: center

.check__textbox__paragraph
    text-align: justify
    margin-top: $spacing_l
    &:first-of-type
        margin-top: 0

.check__notes
    width: 100%
    margin-top: $spacing_xl
    font-size: $fontsize_s
    line-height: $lineheight_s

.check__score
    width: 100%
    font-size: $fontsize_xl
    line-height: $lineheight_xl
    font-weight: bold
    text-align: center

.check__score--high
    color: $color_secondary

.check__score--medium
    color: orange

.check__score--low
    color: green

.check__saliency-map-container
    width: 100%
    display: flex
    justify-content: center

.check__saliency-map__image
    width: 100%
    height: 100%
    object-fit: cover
    box-shadow: $shadow_med
    @include breakpoint(tablet)
        width: 400px
        height: 400px

.check__process
    width: 100%
    display: flex
    justify-content: center
    align-items: center

.check__process__element
    padding: $spacing_xs $spacing_xs
    text-align: center
    color: $color_grey_dark
    display: none
    @include breakpoint(tablet)
        display: inline-block
        margin-left: $spacing_m
        margin-right: $spacing_m
        &:first-of-type
            margin-left: 0
        &:last-of-type
            margin-right: 0

.check__process__element--active
    color: $color_primary
    font-weight: bold 
    display: inline-block

.check__process__element--done
    color: $color_dark

/* .check__process__element-logo
    width: 4rem
    height: 2rem
    padding: 0
    background-image: url('media/images/logo-grey.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    display: none
    @include breakpoint(tablet)
        display: inline-block */

/* .check__process__element-logo--active
    background-image: url('media/images/logo-primary.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    display: inline-block */

/* .check__process__element-logo--done
    background-image: url('media/images/logo-primary.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center */
    
.check__process__element__arrow
    width: 1rem
    height: 1rem
    background-image: url("./media/icons/next.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    display: none
    @include breakpoint(tablet)
        display: block

.section-instructions
    display: grid
    grid-template-columns: 1fr
    row-gap: $spacing_xxl
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: center
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr
        column-gap: $spacing_xxl

.check__instruction
    display: flex
    flex-wrap: wrap
    justify-content: space-between

.check__instruction__title
    width: 100%
    margin-bottom: $spacing_m
    text-align: center

.check__instruction__image
    width: calc(50% - #{$spacing_m})
    display: flex
    flex-wrap: wrap
    justify-content: center

.check__instruction__image__img
    width: 100%
    min-height: 12rem

.check__instruction__image__img--positive
    background-image: url('media/images/instructions-positive.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.check__instruction__image__img--negative-center
    background-image: url('media/images/instructions-1-negative.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.check__instruction__image__img--negative-size
    background-image: url('media/images/instructions-2-negative.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.check__instruction__image__img--negative-light
    background-image: url('media/images/instructions-3-negative.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.check__instruction__image__img--negative-visible
    background-image: url('media/images/instructions-4-negative.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.check__instruction__image__img--negative-background
    background-image: url('media/images/instructions-5-negative.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.check__instruction__image__icon
    margin-top: $spacing_s
    height: 2rem
    width: 2rem

.check__instruction__image__icon--yes
    fill: $color_primary

.check__instruction__image__icon--no
    fill: $color_secondary





// Working

.process-wrapper
    width: 100%
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 1fr auto 1fr auto 1fr
    column-gap: $spacing_m
    row-gap: $spacing_m
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: center
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr auto 1fr auto 1fr
        grid-template-rows: 1fr
        row-gap: $spacing_xl
        column-gap: $spacing_l
    @include breakpoint(desktop)
        column-gap: $spacing_xl

.process__element
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center

.process__element__icon
    width: 12rem
    height: 12rem
    margin-bottom: $spacing_l

.process__element__icon--1
    background-image: url('media/icons/icon_working_1.png')
    background-size: 80% 80%
    background-repeat: no-repeat
    background-position: center

.process__element__icon--2
    background-image: url('media/icons/icon_working_2.png')
    background-size: 80% 80%
    background-repeat: no-repeat
    background-position: center

.process__element__icon--3
    background-image: url('media/icons/icon_working_3.png')
    background-size: 80% 80%
    background-repeat: no-repeat
    background-position: center

.process__element__textbox
    width: 100%
    text-align: justify

.process__arrow
    width: 2rem
    height: 100%
    background-image: url("./media/icons/next.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    background-size: 100%





// Functioning

.functioning-wrapper
    width: 100%
    display: grid
    grid-template-columns: 1fr
    row-gap: $spacing_xxl
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: center
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr
        grid-template-rows: 1fr
        row-gap: $spacing_xxl
        column-gap: $spacing_xxl

.functioning__element
    width: 100%
    display: flex
    flex-direction: column
    align-items: center

.functioning__element__icon
    width: 40vw
    height: 40vw
    border-radius: 50%
    background-color: $color_primary
    border: 1px solid $color_primary
    margin-bottom: $spacing_l
    @include breakpoint(tablet)
        width: 12rem
        height: 12rem

.functioning__element__icon--1
    background-image: url('media/icons/icon_functioning_1.png')
    background-size: 70% 70%
    background-repeat: no-repeat
    background-position: center

.functioning__element__icon--2
    background-image: url('media/icons/icon_functioning_2.png')
    background-size: 70% 70%
    background-repeat: no-repeat
    background-position: center

.functioning__element__icon--3
    background-image: url('media/icons/icon_functioning_3.png')
    background-size: 70% 70%
    background-repeat: no-repeat
    background-position: center

.functioning__element__icon--4
    background-image: url('media/icons/icon_functioning_4.png')
    background-size: 70% 70%
    background-repeat: no-repeat
    background-position: center

.functioning__element__textbox
    width: 100%
    max-width: 24rem
    text-align: justify





// References

.section-logos
    margin-top: $spacing_xl
    margin-bottom: $spacing_xl !important
    display: flex
    justify-content: center
    flex-wrap: wrap

.logos__element
    width: 8rem
    height: 6rem
    margin: 0 $spacing_l 0 $spacing_l

.logos__element--mit
    background-image: url('media/images/reference_mit.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.logos__element--harvard
    background-image: url('media/images/reference_harvard.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.logos__element--sandbox
    background-image: url('media/images/reference_sandbox.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

.logos__element--ilab
    background-image: url('media/images/reference_ilab.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
